@import "../../styles/typography";
@import "../../styles/colors";
@import "../../styles/common";

.container {
  background: $lightYellow;
  color: $black;
}

.visitInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.visitInfo {
  display: flex;
  flex-direction: column;
}

.time,
.text {
  font-size: 0.9rem;
  font-family: $bookFont;
}

.text:first-child {
  margin-bottom: 0.4rem;
}

.button {
  background: $dark;
  font-size: 0.9rem;
  font-family: $titleFont;
  padding: 0.7rem 1.1rem;
  margin-left: 0.8rem;
}

.eyeIcon,
.closeIcon {
  width: 1rem;
  height: 1rem;
  color: $white;
  align-self: center;
}

.eyeIcon {
  color: $black;
  width: 1.5rem;
  height: 1.5rem;
}

.endVisit {
  color: $white;
}

.button,
.activeVisit {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
}
