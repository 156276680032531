@import "../../../styles/typography.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/common.scss";

.statusMessageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.icon {
  margin-right: 1rem;
  width: 24px;
  height: 24px;
  padding: 0.3rem;
}

.statusMessage {
  font-family: 'Suisse Int\'l', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  margin-right: 1rem;
}

.singleStatusMessage:not(:last-child) {
  margin-bottom: 1rem;
}

.close {
  cursor: pointer;
  width: 0.8rem;

  @include hover-support() {
    &:hover,
    &:focus {
      color: $red;
    }
  }
}

.messageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;

  &.warning {
    background: #D2321E;
    .statusMessage {
      color: white;
    }
    .close {
      color: white;
    }
  }

  &.message {
    background-color: #F9A825;
    ;
  }
}