@import "../../styles/typography.scss";
@import "../../styles/colors.scss";
@import "../../styles/common.scss";

.pageContainer {
  height: 100%;
  // background-color: $light;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  gap: 20px;
}

.textContainer {
  width: 600px;
  height: 160px;
  gap: 20px;
  @media (max-width:600px) {
    width: 300px;
    height: 135px;
  }
}

@mixin header {
  color: $black;
  font-family: $titleFont;
  font-size: 54px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-align: center;
  margin-top: 0.5rem;
  letter-spacing: 0px;

  @media (max-width:600px) {
    font-size: 32px;
  }
}

.header {
  @include header;
  color: $dark;

  @include desktopOnly {
    // background-color: $light;
  }
}

.headerText {
  @include desktopOnly {
   background-color: $light;
  }
}

.errorMessage {
  color: $gray;
  font-family: $titleFont;
  margin-top: 3.0rem;
  font-family: $monospaceFont;
  opacity: 0.5px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: center;
  @media (max-width:600px) {
    font-size: 16px;
  }
}

.button {
  color: $white;
  width: 213px;
  height: 56px;
  gap: 20px;
}


.buttoText{
  width: 180.13px;
  height: 26px;
  top: 16px;
  left: 16.44px;
  font-family: $bodyFont;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
}

.greyBackground {
  // background: $light;
}


.advice {
  color: $gunMetal;
  font-family: $bodyFont;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  @media (max-width:600px) {
    font-size: 14px;
  }
}