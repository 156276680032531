.animation {
  transform-origin: top;
  transform: scaleY(0);
  visibility: hidden;
  transition: transform 0.3s ease-in-out;
  height: 0;

  &:not(.enter):not(.appear):not(.appearActive):not(.enterActive):not(.appearDone):not(.enterDone):not(.exit):not(.exitActive) {
    display: none;
  }
}

.animationReverse {
  transform-origin: top;
  transform: scaleY(1);
  transition: transform 0.3s ease-in-out;
}

.enter,
.appear {
  height: auto;
  visibility: hidden;
  transform: scaleY(0);
}
.enterActive,
.appearActive {
  visibility: visible;
  transform: scaleY(1);
}
.enterDone,
.appearDone {
  height: 100%;
  visibility: visible;
  transform: scaleY(1);

  &.autoHeight {
    height: auto;
  }
}
.exit {
  visibility: visible;
  transform: scaleY(1);
}
.exit.exitActive {
  height: auto;
  visibility: visible;
  transform: scaleY(0);
}

.exitDone {
  visibility: hidden;
  display: none !important;
}
