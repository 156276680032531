@import "../../styles/colors.scss";
@import "../../styles/typography";

@keyframes flow {
  0% {
    background-position: 99% 0%;
  }
  50% {
    background-position: 2% 100%;
  }
  100% {
    background-position: 99% 0%;
  }
}

.placeholder {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(200, 200, 200, 0) 25%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(200, 200, 200, 0) 75%,
    rgba(255, 255, 255, 0.1) 100%
  );

  background-size: 800% 800%;
  height: 100%;
  width: 100%;
  display: inline-block;
  animation: flow 15s linear infinite;
}

.loaderContainer {
  text-align: center;
  color: $dark;

  &.small {
    width: 30px;
  }

  &.medium {
    width: 100px;
  }

  &.large {
    width: 200px;
  }

  margin: 0 auto;

  &.lightText {
    color: $light;
  }
}

.loader {
  position: relative;
  padding-top: calc((63 / 30) * 100%);
  overflow: hidden;

  &.green path {
    fill: $green;
    stroke: $green;
  }

  &.white path {
    fill: $white;
    stroke: $white;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.loaderMessageOne {
  color: $dark;
  font-family: $titleFont;
  font-size: 17px;
  margin-right: -56px;
  margin-left: -56px;
}

.loaderMessageTwo {
  margin-top: 35px;
  color: $dark;
  font-family: $titleFont;
  font-size: 17px;
  margin-right: -56px;
  margin-left: -56px;
}