@import "../../styles/colors.scss";
@import "../../styles/typography.scss";
@import "../../styles/common.scss";

.status {
  display: inline-block;
  border-radius: 100%;
  background: $green;
  position: relative;
  color: $white;

  &:before {
    content: "";
    padding-bottom: 100%;
    display: block;
  }

  &.ok {
    background: $green;
  }

  &.warning {
    background: $mutedYellow;
  }

  &.danger {
    background: $red;
  }

  &.planned {
    background: $mutedLight;
    color: $dark;
  }

  &.passive {
    background: $contrastedDark;
  }

  .icon {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .statusIcon {
    width: 50%;
  }
}
.top,
.bottom {
  transform-origin: center;
  transition: 0.2s transform ease-in-out;
  height: 0;
  padding-bottom: 100%;
  overflow: visible;
  position: relative;

  .up,
  .down {
    position: absolute;
    width: 100%;
  }
}

.top {
  margin-bottom: 4px;
}

.bottom {
  margin-top: 3px;
}

.open {
  .top {
    transform: rotateX(180deg);
  }

  .bottom {
    transform: rotateX(180deg);
  }
}

.up {
  transform: rotate(90deg);
}

.down {
  transform: rotate(-90deg);
}

.left {
  transform: rotate(0deg);
}

.right {
  transform: rotate(180deg);
}

.burgerIcon {
  position: relative;
  height: 26px;
  width: 25px;
  cursor: pointer;

  @include hover-support() {
    &:hover,
    &:focus {
      .burgerIconLine {
        background-color: $green;
      }
    }
  }
}

.burgerIconLine {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 2px;
  margin: 5px 0;
  background-color: white;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, opacity, width;
}

.burgerIconLine1 {
  top: 0px;

  .active & {
    transform: translate3d(0, 7px, 0) rotate(45deg);
  }
}

.burgerIconLine2 {
  top: 7px;

  .active & {
    opacity: 0;
    transform: scaleX(0);
  }
}

.burgerIconLine3 {
  top: 14px;

  .active & {
    transform: translate3d(0, -7px, 0) rotate(-45deg);
  }
}

.leaningBurgerIconLine1 {
  width: 1.3rem;

  .active & {
    width: 1.5rem;
  }
}

.leaningBurgerIconLine3 {
  width: 1.1rem;

  .active & {
    width: 1.5rem;
  }
}

.personIcon {
  cursor: pointer;
}

.iconContainer {
  display: block;
  overflow: hidden;
  line-height: 0;

  & > svg {
    line-height: 1;
  }
}
