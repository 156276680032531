@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/common";

.navbarContainer {
  background-color: $black;
  @include desktopOnly {
    display: none;
  }
}

.desktopNavbarContainer {
  display: none;
  @include desktopOnly {
    background-color: black;
    display: grid;
    grid-template-columns: 2fr max-content;
    grid-template-areas:
      "logo nav"
      "version nav";
    grid-row-gap: 0.5rem;
    flex-wrap: wrap;
    padding: 1.5rem;
  }
}

.desktopLogo {
  grid-area: logo;
}

.desktopNavLinks {
  grid-area: nav;
}

.desktopVersion {
  grid-area: version;
}

.mainNavStyle {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 1rem 2rem;
  flex-wrap: wrap;
  align-items: center;
}

.mainNavStatusMessage {
  padding-right: 1rem;
}

.backNavStyle {
  padding: 2rem 2rem 2rem 2rem;
  width: 1rem;
}

.arrowIcon {
  color: white;
  width: 1rem;

  @include hover-support() {
    &:hover,
    &:focus {
      color: $green;
    }
  }
}

.version,
.desktopVersion {
  color: white;
  margin-top: -0.6rem;
  font-size: 0.6rem;
  font-family: $monospaceFont;
  width: 100%;
}

.desktopVersion {
  margin-top: -0.4rem;
}

.navLinksUser,
.navLinks {
  display: grid;
  grid-template-areas: "orders search shunting admin reports separator icon";
  justify-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
}

.orders {
  grid-area: orders;
}

.search {
  grid-area: search;
}

.admin {
  grid-area: admin;
}

.reports {
  grid-area: reports;
}

.shunting {
  grid-area: shunting;
}

.admin.admin,
.orders.orders,
.reports.reports,
.search.search,
.shunting.shunting {
  font-family: $bookFont;
  font-size: 0.9rem;
  color: white;
  text-decoration: none;
  align-self: center;
  @include hover-support() {
    &:hover,
    &:focus {
      color: $green;
    }
  }

  margin: 0 0.75rem;
}

.separator {
  border-left: 1px solid $light;
  grid-area: separator;
  margin: 0 0.75rem;
}

.iconContainer {
  grid-area: icon;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.icon {
  transform: translate(-0.1rem, 0.05rem);
  justify-self: start;
  color: white;
  width: 16px;
  @include hover-support() {
    &:hover,
    &:focus {
      color: $green;
    }
  }

  margin-left: 0.75rem;
}

.statusMessageIconContainer {
  width: 22px;
  padding-left: 0.5rem;

  @include desktopOnly {
    width: 20px;
    transform: translateY(3px);
    padding-left: 1rem;
  }
}

.statusMessageIcon {
  transform: translateX(6px);
  color: $white;
  cursor: pointer;
  @include hover-support() {
    &:hover,
    &:focus {
      color: $green;
    }
  }
  @include desktopOnly {
    transform: none;
  }
}

.orderNumber {
  font-size: 0.7rem;
  color: $paleSkyBlue;
}

.active.active,
.navigationActive.navigationActive {
  color: $green;
  font-family: $titleFont;
}

.active.active {
  border-bottom: 1px solid $green;
}

.logo {
  transform: translateY(-0.3rem);
  flex-grow: 1;
}

.burgerContainer {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.aski {
  margin-top: -0.2rem;
}
