@import "../../styles/colors.scss";
@import "../../styles/typography.scss";

.modal {
  background-color: rgba(24, 24, 24, 0.8);
  position: fixed;
  z-index: 10;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
}

.disableBlur {
  backdrop-filter: none;
}

.dark {
  background-color: rgba(0, 0, 0, 0.8);
}

.loaderContainer {
  background: $light;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 4rem 1.7rem 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
