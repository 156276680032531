$bookFont: "SuisseIntlBook", "sans-serif";
$bodyFont: "SuisseIntlMedium", "sans-serif";
$titleFont: "SuisseIntlSemiBold", "sans-serif";
$titleFontLight: "SuisseIntlMedium", "sans-serif";
$monospaceFont: "SuisseIntlMonospace", "monospace";

$formInputFontSize: 18px;
$labelFontSize: 16px;
$descriptionHeaderFontSize: 16px;
$descriptionFontSize: 12px;

$labelLineHeight: 24px;
$labelBottomMargin: 4px;
