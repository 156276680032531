@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/common.scss";

.reset {
  border: none;
  background: none;
  cursor: pointer;
}

.button {
  font-family: $titleFont;
  padding: 1rem;
  display: inline-block;
  text-decoration: none;
  border-radius: $borderRadius;
  cursor: pointer;
  user-select: none;
}

.padding-small {
  padding: 0.5rem;
}

.padding-normal {
  padding: 1rem;
}

.small {
  font-size: 12px;
}

.small-medium {
  font-size: 14px;
}

.medium {
  font-size: 16px;
}

.large {
  font-size: 18px;
}

.large-height {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.medium-height {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.small-height {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.xl-width {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.large-width {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.medium-width {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.small-width {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.fullWidth {
  width: 100%;
}

.green {
  background: $mutedGreen;
  color: $white;

  @include hover-support() {
    &:hover,
    &:focus {
      background: $green;
    }
  }
}

.blue {
  background: $darkerBlue;
  color: $white;

  @include hover-support() {
    &:hover,
    &:focus {
      background: $blue;
    }
  }
}

.red {
  background: $mutedRed;
  color: $white;

  @include hover-support() {
    &:hover,
    &:focus {
      background: $red;
    }
  }
}

.invertedRed {
  background: $light;
  color: $mutedRed;

  @include hover-support() {
    &:hover,
    &:focus {
      color: $red;
    }
  }
}

.yellow {
  background: $yellow;
  color: $dark;

  @include hover-support() {
    &:hover,
    &:focus {
      background: $mutedYellow;
    }
  }
}

.white {
  background: $white;
  color: $mutedGreen;

  @include hover-support() {
    &:hover,
    &:focus {
      background: $white;
    }
  }
}

.gray {
  background: $light;
  color: $mutedGreen;

  @include hover-support() {
    &:hover,
    &:focus {
      color: $red;
    }
  }
}

.gunmetal {
  background: $gunMetal;
  color: $white;

  @include hover-support() {
    &:hover,
    &:focus {
      color: $light;
    }
  }
}

.darkGray {
  background: $brightGray;
  color: $white;

  @include hover-support() {
    &:hover,
    &:focus {
      color: $light;
    }
  }
}


.transparent {
  background: transparent;
  color: $mutedGreen;

  display: flex;
  align-items: center;

  @include hover-support() {
    &:hover,
    &:focus {
      color: $green;
    }
  }
}

.disabled {
  background: $moderateLight;
  color: $white;
  cursor: initial;
  @include hover-support() {
    &:hover,
    &:focus {
      background: $moderateLight;
      color: $white;
    }
  }
}
