@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/common";

.container {
  display: flex;
  background-color: $dark;
  align-items: center;
}

.transparent {
  background-color: transparent;
}

.closeLink {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: unset;
}

.arrowIcon {
  color: white;
  width: 1.2rem;
}

.closeText {
  font-family: $titleFont;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
