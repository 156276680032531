@import "./colors";

@mixin hover-support {
  @media not all and (pointer: coarse) {
    @content;
  }
}

@mixin tint-on-hover($color, $tintColor) {
  transition: background-color 0.1s ease;

  &:hover {
    background-color: mix($tintColor, $color, 5%);
  }
}

@mixin tint-text-on-hover($color, $tintColor) {
  transition: color 0.1s ease;

  &:hover {
    color: mix($tintColor, $color, 5%);
  }
}

@mixin tint-border-on-hover($color, $tintColor) {
  transition: border-color 0.1s ease;

  &:hover {
    border-color: mix($tintColor, $color, 5%);
    outline-color: mix($tintColor, $color, 5%);
  }
}

@mixin desktopOnly {
  @media (min-width: 800px) {
    @content;
  }
}

@mixin largeDesktopOnly {
  @media (min-width: 1200px) {
    @content;
  }
}

$listItemMargins: 1rem 0.4375rem 1rem 0.4375rem;
$desktopListItemMargins: 1rem 0.875rem 1rem 0.875rem;
$maxPageWidth: 1562px;

$borderRadius: 4px;

@mixin listItemMargins {
  margin: $listItemMargins;

  @include desktopOnly {
    margin: $desktopListItemMargins;
  }

  &:first-child {
    margin-top: 0;
  }
}

$darkShadow: 0 2px 4px rgba(51, 51, 51, 0.2), 0px 3px 3px rgba(255, 255, 255, 0.1);
$dropShadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

@mixin invalidInput {
  box-shadow: 0px 1px 0px 0px $red;
  border-radius: $borderRadius $borderRadius 0 0;
}

@mixin stripes($color) {
  // 8px
  background: repeating-linear-gradient(
    180deg,
    $color 0px,
    $color 3px,
    rgba(255, 255, 255, 1) 3px,
    rgba(255, 255, 255, 1) 8px
  );
}

@mixin container {
  background: $white;
  outline: 1px solid $lightGray;
  outline-offset: -1px;
  border-radius: 8px;
  padding: 8px 16px;
}

$itemBorder: 1px solid $mutedLight;

@mixin inline-h-scroll {
  overflow-x: auto;
  scrollbar-width: 1px;
  overscroll-behavior-inline: auto;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
}
