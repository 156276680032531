@import "./styles/colors";
@import "./styles/typography";
@import "./styles/common";

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

.displayBlock,
.displayNone {
  height: 100%;
}
