@import "../../styles/typography.scss";
@import "../../styles/colors.scss";
@import "../../styles/common.scss";

.pageContainer {
  height: 100%;
  background-color: $dark;
}

.svgContainer {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 30.5%;
  overflow: hidden;
  background-color: $dark;
  padding-top: 5rem;
  justify-content: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.roadIcon {
  overflow: hidden;
  min-width: 350px;
  min-height: 40px;
  @include desktopOnly {
    min-width: 550px;
  }
}

.railsIcon {
  overflow: hidden;
  min-width: 350px;
  min-height: 40px;
  @include desktopOnly {
    min-width: 550px;
  }
}

@media screen and (min-width: 1450px) {
  .roadIcon,
  .railsIcon {
    min-width: 1250px;
  }
}

@mixin header {
  color: $dark;
  font-family: $titleFont;
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  line-height: 3rem;
  text-align: center;
  margin-top: 2.5rem;
}

.header {
  @include header;
  color: $white;

  @include desktopOnly {
    background-color: $dark;
  }
}

.headerText {
  @include desktopOnly {
    background-color: $dark;
  }
}

.headerErrorBox {
  @include header;
  font-size: 1.8rem;
  line-height: 2rem;
}

.infoHeader {
  font-size: 1.4rem;
  color: $white;
  font-family: $bodyFont;
}

.errorDetails {
  background-color: $dark;
  margin-top: 1rem;
  border-radius: $borderRadius;
  max-width: 400px;
  word-wrap: break-word;
  max-height: 150px;
  overflow-y: auto;
}

.errorMessage {
  color: $light;
  font-size: 12px;
  font-family: $monospaceFont;
}

.boxContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  background: $white;
  width: 400px;
  max-height: 90vh;
  color: $dark;
  padding: 1.5rem;
  box-sizing: border-box;

  @media (max-width: 500px) {
    width: 300px;
  }
}

.button {
  margin-top: 1.5rem;
  color: $white;
}

.greyBackground {
  background: $light;
}

.inline {
  font-family: $bookFont;
  margin-top: 1rem;
  user-select: none;
}
