
html {
  overflow-y: scroll;
}

.container {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
}
