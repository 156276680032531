$itemColor: #ffffff;
$itemSecondaryColor: #FAFAFA;
$disabledColor: #f2f2f2;
$ppvColor: #ffc0cb;
$alertColor: #d2321e;
$alertSecondaryColor: #FFBAB1;
$selectedColor: #00b451;
$selectedSecondaryColor: #D4FFE7;
$vakColor: #FC6A22;
$notificationColor: #0078d4;
$notificationSecondaryColor: #D5EEFA;

$textColor: #333333;
$secondaryTextColor: #6e7176;
$tertiaryTextColor: #999999;

$borderColor: #b0b4b8;
$borderSecondaryColor: #d8d8d8;
$borderTertiaryColor: #ececec;


//

$black: #000000;
$contrastedDark: #404040;
$dark: #333333;
$mutedDark: #90959d;

$moderateDark: #6e7176;
$brightGray: #383e46;
$gray: #666666;
$coolGray: #a5acaf;
$shadyGray: #979797;
$lightGray: #b0b4b8;

$white: #fff;
$light: #f2f2f2;
$shadyLight: #e7e7e7;
$mutedLight: #d8d8d8;
$moderateLight: #999999;

$green: #00b451;
$mutedGreen: #00a149;
$lawnGreen: #57a50b;
$brightGreen: #25d060;
$darkGreen: #115740;
$darkPastelGreen: #02b943;
$lightGreen: #b2e3c8;

$red: #da3b01;
$mutedRed: #d2321e;
$brightRed: #f05632;
$alertRed: #d55e3d;
$pink: #f1d7d3;

$yellow: #f9ba2b;
$mutedYellow: #f9a825;
$lightYellow: #efbb4c;
$lighterYellow: #faf1dd;
$starYellow: #f5d069;

$blue: #0078d4;
$darkerBlue: #2952a9;
$paleSkyBlue: #dadfe1;
$violetBlue: #7d5cea;

$gunMetal: #4f5d5d;
$coloredLight: #d1d3d4;

$draft: #c2abff;
$open: #8358f6;
