@import "../../styles/colors";
@import "../../styles/typography";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  width: 400px;

  @media (max-width: 500px) {
    width: 300px;
  }
}

.green {
  background-color: $lawnGreen;
}

.red {
  background-color: $mutedRed;
}

.closeButton {
  color: $white;
  align-self: flex-end;
  cursor: pointer;
  background: transparent;
  border: 0;
  width: 1rem;
  margin: 0.5rem;
}

.placeholderForClose {
  height: 35px;
}

.title {
  color: $white;
  font-size: 1.3rem;
  margin: 0 1rem 0.5rem 1rem;
  text-align: center;
  word-wrap: break-word;
  width: 90%;
}

.button {
  margin: 0.5rem 0;
  height: calc(63px - 1rem);
}

.cancelText {
  height: 24px;
  text-decoration: underline;
  font-size: 0.8rem;
  font-family: $titleFont;
  color: $white;
  margin: 0.3rem 0 1rem 0;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.placeholderForCancelText {
  height: calc(24px + 1.3rem);
}
