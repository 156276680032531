@import "../../styles/colors";
@import "../../styles/typography";

.container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &.vertical {
    flex-direction: column;
  }

  &.small {
    font-family: $bodyFont;
    font-size: $descriptionFontSize;
  }

  &.intermediate {
    font-family: $bodyFont;
    font-size: 14px;
  }

  &.normal {
    font-family: $titleFont;
    font-size: $labelFontSize;
  }

  &.normal {
    font-family: $titleFont;
    font-size: $labelFontSize;
  }

  &.large {
    font-family: $bodyFont;
    font-size: 1rem;
  }

  &.withMargin {
    &.normal {
      margin-bottom: 1rem;
    }
    &.small {
      margin-top: 0.5rem;
    }
  }
}

.container input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.label {
  margin-left: 0.5rem;
  cursor: pointer;

  .light & {
    color: $white;
  }

  .dark & {
    color: $dark;
  }

  .vertical & {
    margin: 1rem 0 0 0;
    text-align: center;
  }
}

.container:hover .label,
.container:focus .label {
  color: $green;
}

.container:hover .outline,
.container:focus .outline {
  color: $green;
}

.outline {
  color: $mutedDark;
}

.dot {
  color: $green;
}
