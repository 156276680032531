@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/common";

.pageContainer {
  background-color: $dark;
  height: 100%;
  padding: 2rem 2.5rem 2rem 2.5rem;
}

.emailContainer {
  overflow-wrap: break-word;
  margin: 0 auto 2.5rem auto;
  max-width: 1560px;
}

.email {
  color: white;
  font-family: $titleFont;
  font-size: 1.5rem;
  line-height: 2rem;
}

.list {
  list-style: none;
  max-width: 1560px;
  margin: 0 auto;
}

.listItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $mutedDark;
  text-decoration: none;
  width: 100%;
  padding: 1.5rem 0;
  cursor: pointer;
}

.listItem:hover {
  border-bottom: 1px solid $light;
  .listItemText,
  .arrowIcon {
    color: $mutedGreen;
  }
}

.listItemText {
  font-family: $titleFont;
  font-size: 1.5rem;
  color: $green;
  text-decoration: none;
}

.arrowIcon {
  align-self: center;
  width: 1.5rem;
  color: $green;
}

.backLink {
  text-decoration: none;
}

.centerBackLink {
  max-width: 1560px;
  margin: 0 auto;
}

.backArrow {
  color: white;
  width: 1.5rem;
  transform: translateX(-100%);
  cursor: pointer;

  @include hover-support() {
    &:hover,
    &:focus {
      color: $green;
    }
  }
}

.titleContainer {
  padding-top: 2rem;
  max-width: 1560px;
  margin: 0 auto;
}

.title {
  font-family: $titleFont;
  font-size: 2rem;
  color: white;
}

.languageSelection {
  padding-top: 4rem;
  max-width: 1560px;
  margin: 0 auto;
}
